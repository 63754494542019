export default {
  getBurns() {
    return [
      {
        timestamp: "2024-08-24T17:32:51Z",
        amount: 9502.09918,
        signature: "Jvz89jXvjzVSTgGYT5c7pQdbQeokeLDttM4XSzf6LCcESDars3kTBLkZyitebCLPnqifTD9a2arWKoBMHycVzZw"
      },
      {
        timestamp: "2024-08-24T13:11:00Z",
        amount: 19734.873556,
        signature: "2ZfLGZmgcbJ81nvAKp4FzThF45Esye8iY8XQyc7wSwVLjNEEiXHzrkinEcddRATXfnwHwMwULwPaJ6uNiEUwviqT"
      },
      {
        timestamp: "2024-08-24T04:17:01Z",
        amount: 10042.136732,
        signature: "4NcuBmNiNfGc7VchAusxopaQr5fJfVgB166v34hGsMkpWQByuGUHxkV9u8e6CN2eidmYsAHSrdUHvnacuFy7gLQf"
      },
      {
        timestamp: "2024-08-23T15:09:06Z",
        amount: 10835.319819,
        signature: "3P2qEsvT5gWfgV5zU25yAxoAN8zXbNLMtsecLKRkYABCGFrisncYn8ufYvMinRKDditPotDhKwsxVkbXhmdiSTLP"
      },
      {
        timestamp: "2024-08-23T13:02:04Z",
        amount: 11059.329888,
        signature: "29CxBgDNCGD9iwJ3Pnh15NX5hB85beLy9RjurmrKfa4uDDtThiXvMuBus7t5Yvstez3cPh9jYpF8M218FrPEwFpq"
      },
      {
        timestamp: "2024-08-23T03:29:42Z",
        amount: 32809.922269,
        signature: "tFZNZdVC8nXj7i5yai2nvtmecv25CoMNg5VkkuuLCdShfiPjfjgQ4LSZRCh2zHNQVo5opQ4UTt67PCcue4LjcYt"
      },
      {
        timestamp: "2024-08-22T22:21:50Z",
        amount: 11190.742205,
        signature: "5wM4ygzjvKJWHAwzgEBAovE79eVwBhScGhS2ccEiScEs1xg6Rpu57hjMDW4ybmp46sxv7jBur78zJLgG8ZFjg4tN"
      },
      {
        timestamp: "2024-08-22T14:45:43Z",
        amount: 44332.50145,
        signature: "3kujpJ7Dx3YCvc6Gx1EjkUHLjr2sRTwNffgkbeh5yjvCaK1QNMQJoMRiH1rtmAmw5i6xdJVP6w2r9V5iQhJfNX5c"
      },
      {
        timestamp: "2024-08-21T20:49:50Z",
        amount: 11185.158169,
        signature: "4DUGQwhrWxY57HfKVCkzv72cKb77q6Dinz38g957qF8q3anKXnp6ZYW94fdeB4pvo792UdrbDgN6nSSAknSwRmV4"
      },
      {
        timestamp: "2024-08-21T14:28:29Z",
        amount: 28074.827291,
        signature: "Ksf3NAVWYeXhSK3hEpQqYiR1JMiveHvWnDP7263FG9xfFkgqWV6emxe4th2Zv9vwp8yrLo3jbbxvFZ1n4dSHHm3"
      },
      {
        timestamp: "2024-08-20T12:15:54Z",
        amount: 19073.730891,
        signature: "2wWXj1XfDUq1aNMoYqeSZtdrcmU3kQRhadsPrnkgWtuhPQkbk4hrcHFxEmJ7Lw8dG3adfoktkZg6nYPm6A6RnUuA"
      },
      {
        timestamp: "2024-08-18T13:03:36Z",
        amount: 12039.307743,
        signature: "2vzApA1diobuB18ejfzHCRThZKnDY4sJ96usdnf9S7E8u3U6yktRrDPUDP73fZHsNxvbnRyC9aLQsn4C4jtS8GZV"
      },
      {
        timestamp: "2024-08-17T05:14:13Z",
        amount: 9494.416727,
        signature: "3QETc4X2bkT56DhnFL2HGxS5jCKgL2aNc2G7m96Boy8es7YXnbE6o7KQL6v85LVJfoFWSsJvMhxeea7jZocZdkTL"
      },
      {
        timestamp: "2024-08-02T18:50:25Z",
        amount: 26787.328813,
        signature: "4vS881vbce4ciF9GaJTUUm3H6hDH59ti5ZVS1q57dija5Caa5ojcqbXqiviKEXpEo3iWEqfgNMz7CtKzDTTyYHCr",
      },
      {
        timestamp: "2024-08-01T22:56:05Z",
        amount: 7811.914881,
        signature: "4wtp8HqSenxCi9tFm6GRD3XQqd2EAgf9bmuycuLEE6PN1PM4oYvPrr2uPqVprk29L79cYCDZaE5HJZZmP3s4RWFR"
      },
      {
        timestamp: "2024-08-01T21:44:40Z",
        amount: 15804.962392,
        signature: "ANzXe8hR32eZdxw8TjVbYQ7cPhCE1Sd7ZQKCnwvBjm6qvCgoi4wg2vJu18ahpggpMSzoR9dkvgDVThM2pcaHGYp"
      },
      {
        timestamp: "2024-07-30T13:53:09Z",
        amount: 9754.429761,
        signature: "2UpLtqipGHT39qVFwa3roJhsfQnSevtLcNtVmxNknbk7d9Mu9odot5giohraJebfLcVdetqpqfNAvRRHPkJvTViE",
      },
      {
        timestamp: "2024-07-29T04:26:36Z",
        amount: 22370.728949,
        signature: "3gWXi5orcCqc3bUXd9HCpPpEvJ7oCBmXU3uwknvUPUxWeY5ZffRPffL88JP5CFSvykaD6KB8U3C5XJCaqnG59TdU" 
      },
      {
        timestamp: "2024-07-28T21:45:14Z",
        amount: 12604.577275,
        signature: "5euGmfWsaDrwn74PPHEnn91Xki2bGXTwzeDf5g6YK2BBBHQm35oCLswoXw5YiSDc9b9NceyFKGpGfXfNCxPPy8gJ"
      },
      {
        timestamp: "2024-07-28T02:03:45Z",
        amount: 14388.525397,
        signature: "2oKu6vevpskBVtHCL8FPCAvthQePNCbPfXVgwxMQ3kviGF2fSXPQMWkDAYVMPgWfttSLoUbnG3Y2oJaoveyviMbC"
      },
      {
        timestamp: "2024-07-27T14:13:23Z",
        amount: 14996.874938,
        signature: "4dGTAz57wnUDezsyjiZ7gVgA1em9uNBsSJoc44brybEjhMNLVYjtB72VLpPv7eHJ5pn8SJN8REnzD4y8bY2bsDze"
      },
      {
        timestamp: "2024-07-26T20:34:06Z",
        amount: 17286.40144,
        signature: "B8s76Q2w9y5Tq1KBi8r6apb9hLy4WBZnRNrECfLgh1yfF2Uo59JkNpJFFd1b1kY4MAhMFcbnhx6H6dMBAw5eBop",
      },
      {
        timestamp: "2024-07-26T12:38:34Z",
        amount: 18430.101016,
        signature: "47iWuNLqFmki4CxoMMRxM3zGWibXJKNx57bJFM8owB4BGmhPEZK2s1RSt22nn7uBAVutytFy9sPfKFaoJQwUaDC7",
      },
      {
        timestamp: "2024-07-26T05:40:44Z",
        amount: 19308.429238,
        signature: "zLUsDBwFzGDji56Tc63ZZmHnKoSx13X3ZEAmHgEQSsxtb8hmuTYxxsLCwU6iiLkN26DRPG6GPa9vZVpeqvienHR"
      },
      {
        timestamp: "2024-07-25T22:08:04Z",
        amount: 19662.330034,
        signature: "5nQgwyspmhtaXf8BgrGzq6v9jvPWsDnspzAZpFTG8HkoEFVSH6DdnFPeKJ7xS2tmiCToPmmMxjuztQ4NeFbjGfBo"
      },
      {
        timestamp: "2024-07-25T15:52:58Z",
        amount: 20026.566686,
        signature: "9wXLHSXnN7thi3pik6s3ZhrU68grq5dCmSubGGemLRriGGS6EkAetVZp9VzRs7atFo983pqr2NnxeNTK4qZdQG3",
      },
      {
        timestamp: "2024-07-24T13:29:16Z",
        amount: 37077.606512,
        signature: "2EksxDS2MRccz1LWqtVJZdLYX77bpCoLxmGwmfFSUTpYEnyjRXDa8Bb9nPhw15MDGjHWJFr9rFSdfyLWKmdLeEfW"
      },
      {
      timestamp: "2024-07-23T21:58:54Z",
      amount: 19077.715393,
      signature: "44nrD8juHdxYBaEihsYz8Lisp7XMnB7fUBivQBK3giMtWzzQTnT9cSGhmMwNprV8RA87c8UZ1iryq8wN6jXduRVv",
      },
      {
        timestamp: "2024-07-23T13:21:30Z",
        amount: 19009.119218,
        signature: "4pKZ1BtcnyH4TUtwv9swNDNEXGugRnEtG5ReL6nuA3DKQPCRPAHmfN9KjR41qPzZLh37h5uj2McxbWfxBMiUDSFj",
      },
      {
        timestamp: "2024-07-22T20:31:22Z",
        amount: 20575.881752,
        signature: "5boCcupP4z4pdqUwcgz8T8HRpRU5PXqfYTQ9Fi7Gf1MP5TshfdED6DQbukSNRAC6chdoJpAFvfQHUpjvivzza1ue",
      },
      {
        timestamp: "2024-07-22T00:12:11Z",
        amount:18434.04971,
        signature: "4ftA9NvXCMxF1wMhzDgqYRRjBPNpXNWb9QVSoebK9ooeZGzYQuTxyn1URaR3c2GTVGP6GNndgrJvcft4rGUtow5F",
      },
      {
        timestamp: "2024-07-21T20:34:47Z",
        amount: 55624.664967,
        signature: "2AnHcdQV25u3y1KAxNHXnPX6wmcmvcra15M3sk9TKW87nvjrUGc7VcDF4QzBxE2eDaAyqYryf7Ru8esqr5YP5zEo"
      },
      {
        timestamp: "2024-07-21T16:12:40Z",
        amount: 58179.966766,
        signature: "4geKkNAn6AvaQnBgLDgfiS9gQnBGgAaQHZA9RTveYeG6WTpEE2AtHung1kqhsjhYAknfAVubp7K6vHijEWj5Cfy8"
      },
      {
        timestamp: "2024-07-21T14:43:12Z",
        amount: 19689.500194,
        signature: "67HQ1J1BKvTdHubnFNMeJYHYRn61poTTpza8EZNffAF1cnBHMDPvgB1vPcTbE7zJBPd2CYyZQMJLYCGYTPRuaHyi"
      },
      {
        timestamp: "2024-07-20T21:48:11Z",
        amount: 38909.501627,
        signature: "2DCy6QSRi5w1EVXniu2h52oLHNMki1DWJyZwDqfNv3zb5hwwWNeWfBqyVW2nMRsnFd8GaKHPGUBXDXTHeRxBAXuV"
      },
      {
        timestamp: "2024-07-19T19:51:31Z",
        amount: 19865.114904,
        signature: "5Jb5ca3imKAevmDu2Uqy2TaJsT93P6Ey6KSWr3FYccpWt64MpQwqDBYoGYc9AQ1z7GJwf5tLW2CE6RyLKXXEDbZB"
      },  
      {
        timestamp: "2024-07-19T16:29:43Z",
        amount: 40576.160354,
        signature: "3NjCQKg4u17hg6a3W5hp7WszUsaKLskJVpcHjxySURXbHLbnEuiTuMAs6AWfRWzKLrEUQqru6AmpMnxyp1UikmUG"
      },
      {
        timestamp: "2024-07-19T06:58:23Z",
        amount: 21192.785753,
        signature: "3b4X5teemkk8GdEjipoC1B4biA4Bk26HqJWtHE1uv1VWpAjLuwDA8KGg2o3s1vQ5F1QyD5kJbF4gDhebTU3N9MCn"
      },
      {
        timestamp: "2024-07-19T00:07:34Z",
        amount: 21950.835137,
        signature: "5woYcW51sTkEfoCuArA2tdW4GT3WxixHcZoD7ndu6JzeK8DTBpFgpevdRkYzSUmXUu9phjEn8v63kuzY7Y4jQbsT"
      },
      {
        timestamp: "2024-07-18T06:46:35Z",
        amount: 22033.132772,
        signature: "2dzXhfESfTASMSgDhup5zNg5Hca9TBUvknBWTrQP4yRTcg7YE9njFEcuBxRYopSiW7271rHx19oyPF5KV4HaCmQa",
      },
      {
        timestamp: "2024-07-17T04:33:35Z",
        amount: 22094.863505,
        signature: "5WY97ohGdjidhuioSRA6WQAw4cwyqRhg5H2XM6A833j8DBiUcddQRNZ4K9n98JggNGQyzun1VaMkTPtYvLR6DBvr"
      },
      {
        timestamp: "2024-07-16T17:04:08Z",
        amount: 42192.970859,
        signature: "2wprV3gsri6QJB2gSjiudp3fZqtZFpZS29Zq9jfDGQB3aoQb1QD5q2RcuzXYxLK4x1ty2i71LimfohXBaQKCADpQ",
      },
      {
        timestamp: "2024-07-16T15:21:42Z",
        amount: 22180.853241,
        signature: "3oM4BSwCKHNPZxwQ6BVr9bHzPiPcgRor2nEiKpnzTUro4iLUmEPYjMku8hApjnhC1PrtuwoqAnLpnvjq5BRZH8Jr"
      },
      {
        timestamp: "2024-07-16T13:05:45Z",
        amount: 22560.466387,
        signature: "32wHtg5VEw7g2PMurugWkqe3ekhBY6Sur7SNfnATJgVFWEvfbAtjakRoUjRu8ADHnzwjpph8vusDyPLWNcRB7dzy",
      },
      {
        timestamp: "2024-07-16T01:45:08Z",
        amount: 43094.991025,
        signature: "3SXWfTeFFfFgiz1uRqxDTsmzdFEqqckkn4tKszMdA8K4AC14Yn9LwWdLJUzYYoUjJKKH7DUEBj6N79q7vHYXoASr",
      },
      {
        timestamp: "2024-07-15T17:03:34Z",
        amount: 21474.78371,
        signature: "567xnwFrVGhy9mzA3nYmEcfvX1E2sJkjGaJzW8uzuHmz8Un3EVBWybR1TYHpLkAtpKXZGdEKn9U4KUgHWmNBxqG3",
      },
      {
        timestamp: "2024-07-15T04:15:08Z",
        amount: 43281.447121,
        signature: "2UQxnHJxsYURqSBdrTvV6j8SvS8ES7QjeqqG8A93kDTZh11DmQ4X3HorRhmqtY5NhX3L4BA4NZo6xUabwubQjA3F",
      },
      {
        timestamp: "2024-07-14T07:26:10Z",
        amount: 22626.459606,
        signature: "42dcnewdbPEde34ohpjz5ZDzXRN6VG5Nu5euuhYpK4VaFwkw9gxh1wqSZUzsorJB7PkNL5RoQGus8fq6XQPN3Uzp"
      },
      {
        timestamp: "2024-07-13T16:28:47Z",
        amount: 69967.328416,
        signature: "hY27aLUjF74dWak2dw5MjvZNCFv8g6ZEeDMA3VANPTVv8ioq5DLfXaQ1YBWeSMAdwLZrgV5DU54Kck1MydjVFM5"
      },
      {
        timestamp: "2024-07-11T14:42:48Z",
        amount: 45308.052746,
        signature: "4X3D1BTbT8YTBLWUTkXJKWD7eyC3FsVE25JPy7Jk5na9GVzZpKiuArT1RMox6JVz78dqJPxJHwxtemEVhh83GFYs"
      },
      {
        timestamp: "2024-07-10T13:42:07Z",
        amount: 44295.715926,
        signature: "3dB5QX21XE1AjxagfBbix7SnyaAYxLPAcPhKEB4W59MyurW2jjJpTM64a2B4KJXxZnVc5bJ6R8Ca1HHve7inWF1J"
      },
      {
        timestamp: "2024-07-09T21:05:37Z",
        amount: 22266.605979,
        signature: "3u3h36TssM99NmtptPFM4E2xGb2h7Q7PpnUFVosDsgSiLxeP8MCMU3vswj8KuQwLSqb4nKJ5nnZdztDPvrqBJz5H"
      },
      {
        timestamp: "2024-07-09T16:54:38Z",
        amount: 21926.7137,
        signature: "3G8FnVDy9ptPdTzLyzZxwuKGoBdTJX6inHCsihGTiKbDjVaEBVRHRRrtDyRg1z3ifgRKpbZ7wKhcgJU6SaYbCfyF",
      },
      {
        timestamp: "2024-07-09T12:58:49Z",
        amount: 43696.917907,
        signature: "4mwFEnbRtfdpy2xpMp7tfw3DPm661HypQCBjNJoFrwgkKuc3SPSStTYK5vDitVNQmqctwCM5A1Thg2bmX6JzPJyG",
      },
      {
        timestamp: "2024-07-08T19:21:58Z",
        amount: 22748.488215,
        signature: "BtVBE8275LKVSoydoiwAUNsNVde1kDLeKFxR8Y9FNMYXNMd71tcHuBVXCFtLXVqEwMHvTWsEjgHy56mhd5tP33t"
      },
      {
        timestamp: "2024-07-08T12:49:02Z",
        amount: 89171.773047,
        signature: "42WWA6rxeYafRWkrw1oecRmQcWBtHmueqc7taqvYLmuF2fvA3bh4ZGvxZdh6QtwFkayLqJ874rHHrQXQSLGHrS6S"
      },
      {
        timestamp: "2024-07-07T20:29:03Z",
        amount: 22826.038926,
        signature: "3Z6MwR4zVKZ4iaSa9DaRSi3dJPjTBnRx1wRoqWZANRyAqG6JERWoEZEbmZFGJYE564UGMbgGLeioBVNX3uocsvbZ",
      },
      {
        timestamp: "2024-07-06T21:52:26Z",
        amount: 21863.170878,
        signature: "5gmnWQn4frsuRrboezpyY1rnqqjjSqG93Pn4CkqaBuMeVCEg3oRxRSe2MxqKKjmiNU6EGhQBDjueVLUAxvAWXqwj"
      },
      {
        timestamp: "2024-07-06T17:35:09Z",
        amount: 80712.929263,
        signature: "4o5qCLfHmbymJ9QVE5xgtaRaV33wR9v5Mmks46y368uRdW38eBo2jyXxcDs36q7FpAkvgQJ8epZESrrBKQ6MfWqC",
      },
      {
        timestamp: "2024-07-01T12:43:24Z",
        amount: 108125.257232,
        signature:
          "38sZRR7rPTcUX1szzFmrRJLVS4x6f2Z8sJPkQfvGB2xNEKCuvfhQiS51FA4ZpZPJABFugS36PoeQNBuLh8ktwjyn",
      },
      {
        timestamp: "2024-06-30T16:20:14Z",
        amount: 20734.905531,
        signature:
          "2wrY6Yjugtef9m9Chfapdieq8o15Q586qNs7FQ1saKotc9pNzqMFoS665WYiBDYMDgpjvAhqY78iJZ3ccGfoXrtE",
      },
      {
        timestamp: "2024-06-29T12:30:27Z",
        amount: 48085.972545,
        signature:
          "4Teiwmvj6pj41kVyoe8NZy472NSzxvopXcGVZ9joQBafXsTsSohETqqg3Fux9bVk94QEFSnZpvajvsKX7dCRsg2h",
      },
      {
        timestamp: "2024-06-28T12:49:50Z",
        amount: 23317.893645,
        signature:
          "5K2vp7gzPqfxkehsFhccgz6aHqvJVcsZi8jybjP22htWq3kCBTaVUjSZYJGSuBneFcBE5dWKAM4C6REsmeLt9HAi",
      },
      {
        timestamp: "2024-06-27T20:51:13Z",
        amount: 46840.270593,
        signature:
          "3zQJT5L8ir8Fi5UaeTcinXJvhGPouhuniMcTYgqedoyLkykZERFvWfgrwUf4HmKkQa1ZR82KgjmPTf45SA6QgmLc",
      },
      {
        timestamp: "2024-06-26T13:32:58Z",
        amount: 25826.377666,
        signature:
          "4pbc6asvom7jZT9obXAzpH5YH7RGux5skqPfPt9PGCGWWePivWGYdZX6jCADF7etZPpH7qRbMjx2SgJzyd4Xx7X6",
      },
      {
        timestamp: "2024-06-25T13:22:03Z",
        amount: 26413.194129,
        signature:
          "3F4hEGga3V17xe9XdK5hMosgwAUEHdQvQfKKhXKZRLwta1ryEotWXtLpQK6mkKmpxg5YTdNZ2paWYqSU77qxx17c",
      },
      {
        timestamp: "2024-06-24T22:17:53Z",
        amount: 31607.849793,
        signature:
          "2wkDw3pSGnV9mTNsBAAEz4CaENkGN3zWc5svp7kuCGQDqYy5TyJKf7Wb9eEybaPVbC62J7yqTi8FyM9K17pbwUpk",
      },
      {
        timestamp: "2024-06-17T18:36:38Z",
        amount: 26929.057131,
        signature:
          "2wQq8JNmL1DScxzdxmnpAVBqVHDE1RHcmcjVm9vwvxXsnpA6PYeEfvn5YVrjWrc9t6SG5CbSph5b8Kztq9vRNGa",
      },
      {
        timestamp: "2024-06-15T13:48:06Z",
        amount: 27564.526817,
        signature:
          "cz8DVkwc26aYS76WtMU94aT8Pu4qEodEHXohtguusTeCwQRVZR3cH7WKRkFbzY9p9GzUr79uqg5PtxBejsyFLZr",
      },
      {
        timestamp: "2024-06-14T15:13:04Z",
        amount: 22307.826402,
        signature:
          "4Ba8bwHCwQN3xgwWCMMKypMBipFjtt2WWd22VgDEjG6N9VpNNmVe9THzK86NJ5VqSqinWSEdQzafEaWqJAqfZqEo",
      },
      {
        timestamp: "2024-06-13T12:58:38Z",
        amount: 19752.114589,
        signature:
          "49vg35D7Yb41XEvBbTawbbirDdtV7WbmZA4sjHQZsw4Xek56YVeDRJNemSwjKexdDsxfjSuZ1Bxn8sBdTVKj9D6M",
      },
      {
        timestamp: "2024-06-12T21:00:17Z",
        amount: 40062.99429,
        signature:
          "2Pi2z1KWmfsGDuMAUm6NWzFNkHBs1xpEkEyRhtj8pJpmEXTa8eoHFFo7Ydu8sDK9fnwpAKjZUQsKpZprJUX627pJ",
      },
      {
        timestamp: "2024-06-12T13:33:48Z",
        amount: 19271.159335,
        signature:
          "BEJk3218zvjASAN1GB9xvcfpfapQ14iNKF5DK2NdNFS9XS9Sqx1GWCzEv23n1y6VeB3WaJKLLxYVYGJV4igYHvQ",
      },
      {
        timestamp: "2024-06-12T04:22:30Z",
        amount: 20828.79119,
        signature:
          "2LEvURdQwa1yDiyKser2v4RLVmPj2TZD678gsd7jajbqrooXPGUFKAPx28x93d5AgvYEvnZHRPhp7j6DtR8haBEh",
      },
      {
        timestamp: "2024-06-10T12:52:43Z",
        amount: 17747.052428,
        signature:
          "5qyJxfJ4h8zisWxxjrwLZyYeoNuiubhrzxXH1sPnPvdQ8iYmZZUNPJR9AHP9zC3kzFWNoJdaZdW7LptAsiFnQGx7",
      },
      {
        timestamp: "2024-06-09T13:12:07Z",
        amount: 17588.975961,
        signature:
          "puh2z4cdCdWbCGo6mRpeF1mfBKkope9WnZyt11TJby59jveUYGehETNftaPYT1QzPRXpEm9jaUNcNRB5vxQDAg2",
      },
      {
        timestamp: "2024-06-07T21:29:10Z",
        amount: 53829.57226,
        signature:
          "AVEb8sF3kwdJEjzTvygWoVgFxtyL9RAbaaFQxPh6REnUnY35oJG36bCrTT8E8n3wPNxVTrDwJzcFbaFq3kBLjEs",
      },
      {
        timestamp: "2024-06-06T12:27:11Z",
        amount: 21362.526318,
        signature:
          "2RtFZ4QPPnQoTghDR2oQSu44ThYBJ1xkMij2zWTuTZidVsVwzoTTuAtTWcfVXsPRYaqFBrpGsz1PqCKLs3YdACzJ",
      },
      {
        timestamp: "2024-06-05T11:49:22Z",
        amount: 16403.93283,
        signature:
          "2R5j92jmCwoDEThTo9dA6WYnB4DJjbYxJNpL7jWqhL2Hjkn2gAmrswUarYPSxhVnisjCeoFp6vCY9U4fLu22tNTG",
      },
      {
        timestamp: "2024-06-04T15:08:34Z",
        amount: 17542.617244,
        signature:
          "5uS7N1mGSUEheDZ4Rqos2snyXLk23RqsQPDURGWTRQJ3pJGjh2JKQupRZL4cNv9TpYfU6PVXeQmAAF5DJyHaGDVR",
      },
      {
        timestamp: "2024-06-04T13:43:08Z",
        amount: 17108.252198,
        signature:
          "28fdzx9FKvDVNooDbgxMe64gkVURJAaU4u9Qt5UWmu6myt86eH4cTsHKNTqBBDdmVYyZuZAwMuo9cH4WgCT2A4tH",
      },
      {
        timestamp: "2024-06-03T11:48:28Z",
        amount: 34428.829607,
        signature:
          "4kjvwuK5t7KqjkGNr1nauTh2iiyijCmrLWQ1bgGhaRn1bC6uAkPgxmn4NPe5vJ6nMTyBBMWw8QKA5MnzW7P5FqJy",
      },
      {
        timestamp: "2024-06-01T14:26:10Z",
        amount: 16196.485642,
        signature:
          "3pxG8VZb8hg1SNaREjRYFracihpRizSn7wH8quBComr6JMYqX8cEAaWCsmSwryk6Uks1U48Y756zbQhBfnZmJ7ek",
      },
      {
        timestamp: "2024-05-31T12:43:43Z",
        amount: 17140.04978,
        signature:
          "5fDyoj8V7TEVEQViHS7UaiqkoSNTD3SFA36Esy2btKeTwTgGpqvDCV4YnEs6QxejBCE2dPz27DqLNE66DZKyFyF9",
      },
      {
        timestamp: "2024-05-30T13:10:45Z",
        amount: 46008.809213,
        signature:
          "5M33ouGTbfSENNmN9CfZ82J2zQW3xWDRopiLFKKJkwBzb651swCUivaS7uz4kq9GY6o9kXPMeQBvpBpNk5EMos53",
      },
      {
        timestamp: "2024-05-28T14:59:00Z",
        amount: 21591.396912,
        signature:
          "5mbMMdM6LEVEyEwnWEzWhsdTxmc6hXvpY1xjUTmWjRstVmFh9EkRH24bvbjVwLvTTu5BeLtA6CAia3uLPZGffh4J",
      },
      {
        timestamp: "2024-05-27T12:58:02Z",
        amount: 17116.934457,
        signature:
          "4amhkWh5CEH4aD8xfQNiqy6P9xpajghRmnDWrDgeeeKto6sJTg91vCaWWyHJmD7b47gFaS5YFabgdQt8xxujn8tQ",
      },
      {
        timestamp: "2024-05-25T17:24:16Z",
        amount: 14255.810248,
        signature:
          "2Uu1SuCQx3Ud5wNDdgRX3Vz5yppnkDbJdzjJgxS5aj7a7KyrQaRxjzr3nxbXm6JuqS3N6bKHjmapqCeZcMB9wxaU",
      },
      {
        timestamp: "2024-05-24T12:40:51Z",
        amount: 26244.863566,
        signature:
          "5EGJfV6cuoxfLdbtK9QMdcBgdrzrQXYGH8xqAaj33r2gLiHcoRnYRgdigKTwKFZSNAD1VbamF8o6LNsfgN16C25w",
      },
      {
        timestamp: "2024-05-23T16:14:38Z",
        amount: 10632.283902,
        signature:
          "5vDSRqGqPAHxxa4hvgzcpGddSNu4zMnjCYQLToNkvDr5v4K6RAz6Pa6eq38pYGQnvkpN7gnsoHuTjwnvH2FBqjY1",
      },
      {
        timestamp: "2024-05-22T12:02:06Z",
        amount: 26564.784172,
        signature:
          "wW8qbp8HXDXqCGZ5EhjU58SnUT4TXEPqgxbcovEYnVqmeRhZNogaCah1CLydxcAgyuqUgHb4mKLchUivwie9wQJ",
      },
      {
        timestamp: "2024-05-21T12:56:38Z",
        amount: 64337.728902,
        signature:
          "5CioWCkiiugDhMr4ZyLuwjMgST18GhDppW4VL9DwDdM7f9sWYzeMs3QFFdqQ12CXfEybQhnhARnkwquHiBKntRxg",
      },
      {
        timestamp: "2024-05-20T15:40:08Z",
        amount: 8495.1974,
        signature:
          "3eMeJ7CaqNNb8d8ATTdBrk6u945JKLcSSAoRq2xdUoNFjmwZ6127ucwsnmEFqHW1rQS8vjTkNvpVUspENsgPmnzi",
      },
      {
        timestamp: "2024-05-18T13:29:54Z",
        amount: 8886.240525,
        signature:
          "5LpFpp6bJSccQW4PZPpZg264A2Yuw852AfBh2avgfu3YdWXup5KAwNTXXTg5kmvXaKii6maTdo9BwaFBPNDrb28s",
      },
      {
        timestamp: "2024-05-17T12:13:02Z",
        amount: 49645.973374,
        signature:
          "63HQfzYaHCris11qeK9ojTUzFVscBu278qZACXUtX38hHNwUU7WWTUxkKYc6BfU8SdNWyWBNx9EvKg28NMgfXT2y",
      },
      {
        timestamp: "2024-05-16T12:40:53Z",
        amount: 13884.607167,
        signature:
          "67KxC7gSDBViLdruT68BzpnCbPNNhrhU9AXYTd8AfpG5QXukckobTFgZYRWxYH6AD4XvDhoemd1fVgSKkiSdursB",
      },
      {
        timestamp: "2024-05-15T15:04:27Z",
        amount: 22366.797761,
        signature:
          "5RGHVj4uuntbQKbtisjmXW6PnsoVe3iyTrwMoq4H41zUEHx7UWk4cFRoKiT4CJfv12fRyeNmN1DgRpt3nogH4qwy",
      },
      {
        timestamp: "2024-05-14T13:42:09Z",
        amount: 14323.537005,
        signature:
          "yWMYnsaTXQrcJJsEDuz98cSqJJUBK91r8JzLe7CmYEdvURajU4k5xJzgfD7RmSnro3hWZ3CWyk9Ptpk3zjWvqxx",
      },
      {
        timestamp: "2024-05-13T12:25:52Z",
        amount: 13142.473715,
        signature:
          "2kGeUHCUJHawCDKuXDLq7oykYJVnkUsD5JkPr4H6ucKH7aGoSrhkQks6wtAg2ug4TLBiYSXX9Fk84j9W1Cyaigrv",
      },
      {
        timestamp: "2024-05-11T16:36:40Z",
        amount: 14502.248819,
        signature:
          "5SJ69sVvF1NFgA1VTvXQPruUGu2nTntvghaah6LKSPiJkjkSi9C42sNDZwAFXh1D3pZJzrEAU6G3F8X4Qs1oNFbz",
      },
      {
        timestamp: "2024-05-10T12:49:24Z",
        amount: 24962.800858,
        signature:
          "2u1sW5u9PZoLQdFjprXim72yEXow82shrjo8mDdPgWNSrNStAjdWgFjgZAjRMMytKga21uTtBSktB3wAj1fksxPK",
      },
      {
        timestamp: "2024-05-09T14:20:31Z",
        amount: 31767.811985,
        signature:
          "3aGYLYCNyPGLYhtRrht2bqYqn2Jsx5YWDe7qt3ezg8mrkS5VKFRV7ng7FjJkmzQbuXFGwXWBf4RZufm5BX3CbkFK",
      },
      {
        timestamp: "2024-05-06T13:03:14Z",
        amount: 110846.016924,
        signature:
          "52jq4aCpnDyp32AFRFdJ6sq4GoxGQTdMU5s5m1931RtcgG2uMCVvqezfAggCnWWiBjxp57cWwVGD8gvMPZ719u8e",
      },
    ];
  },
};
