<template>
  <section class="view">
    <section class="container">
      <h1 class="title hover-glitch">TradeBot Burns</h1>
     
      <div class="phrase">
        <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
        <h2 class="phrase-text">Feel the Burn</h2>
        <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
      </div>
      <div class="stack" style="--stacks: 3">
        <span style="--index: 0"
          ><img
            class="tradebot-size"
            src="../assets/running-bot.png"
            alt="blue robot running"
        /></span>
        <span style="--index: 1"
          ><img
            class="tradebot-size"
            src="../assets/running-bot.png"
            alt="blue robot running"
        /></span>
        <span style="--index: 2"
          ><img
            class="tradebot-size"
            src="../assets/running-bot.png"
            alt="blue robot running"
        /></span>
      </div>

      <div class="button-flex">
        <a
          class="buy"
          href="https://solscan.io/account/HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT"
          target="_blank"
        >
          <div class="button-row">
            <p class="buy">Burn Wallet</p>
            <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
          </div>
        </a>

        <a
          class="buy"
          href="https://dexscreener.com/solana/7mlmtmvrgg9o7m9qdeezeq4axuurtrsrtbpcwmnlw1y1?maker=HrQBXyKh2mgPoLa8Kka9Vd9hBrCtWgxtAkyvRd8tq7dT"
          target="_blank"
        >
          <div class="button-row">
            <p class="buy">Burn Chart</p>
            <img class="avatar" src="../assets/fire-icon.png" alt="fire icon" />
          </div>
        </a>
      </div>

      <table>
        <thead>
          <th>Timestamp</th>
          <th>$TRADEBOT Burned</th>
          <th>Transaction Signature</th>
        </thead>
        <tr v-for="transaction in burnList" v-bind:key="transaction.signature">
          <td>{{ transaction.timestamp }}</td>
          <td>{{ transaction.amount }}</td>
          <td>
            <a
              v-bind:href="getSolLink(transaction.signature)"
              target="_blank"
              class="signature"
            >
              {{ getDisplaySignature(transaction.signature) }}
            </a>
          </td>
        </tr>
      </table>
      <section
        v-for="transaction in burnList"
        v-bind:key="transaction.signature"
        class="card"
      >
        <div class="box">
          <p class="card-text bold">Timestamp:</p>
          <p class="card-text">{{ transaction.timestamp }}</p>
          <p class="card-text bold">{{ getBurnHeader() }}</p>
          <p class="card-text">{{ transaction.amount }}</p>
          <p class="card-text bold">{{ getSignatureHeader() }}</p>
          <a
            v-bind:href="getSolLink(transaction.signature)"
            target="_blank"
            class="card-text signature"
          >
            {{ getDisplaySignature(transaction.signature) }}
          </a>
        </div>
      </section>
    </section>
  </section>
</template>
<script>
import burnClient from "../services/BurnClient";

export default {
  name: "BurnView",
  data() {
    return {
      isMobile: false,
      burnList: [],
    };
  },
  created() {
    this.burnList = burnClient.getBurns();
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    getSolLink(signature) {
      return "https://solscan.io/tx/" + signature;
    },
    getDisplaySignature(signature) {
      if (signature && signature.length > 15) {
        return (
          signature.substring(0, 6) +
          "..." +
          signature.substring(signature.length - 6)
        );
      }
      return signature;
    },
    getBurnHeader(header) {
      if (this.isMobile) {
        return "Burned:";
      } else {
        return "$TRADEBOT Burned";
      }
    },

    getSignatureHeader() {
      if (this.isMobile) {
        return "Signature:";
      } else {
        return "Transaction Signature";
      }
    },
    checkScreen() {
      return (this.isMobile = window.innerWidth <= 750);
    },
  },
};
</script>

<style scoped>
.button-flex {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 3%;
  margin-bottom: 0px;
}

.button-row:hover {
  border-top: 1px rgb(216, 5, 5) solid;
  border-bottom: 1px rgb(216, 5, 5) solid;
  box-shadow: 3px 2px 10px rgb(216, 5, 5);
}

.signature {
  color: rgb(227, 227, 242);
}

.signature:hover {
  color: rgb(78, 111, 211);
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

table {
  width: 90%;
  padding: 10px;
  margin-top: 3%;
  margin-bottom: 3%;
}

th {
  padding-top: 5px;
  padding-bottom: 10px;
  text-shadow: 2px 2px 5px black;
}

th,
td {
  padding: 5%;
}

.phrase {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
  color: rgb(216, 5, 5);
}

.phrase-text {
  margin: 0px;
  margin-top: 5px;
  text-shadow: 2px 2px 5px black;
}

@media only screen and (max-width: 768px) {
  table {
    display: none;
  }

  .card {
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .box {
    --b: 2px;
    --c: #888888;
    --w: 25px;

    border: var(--b) solid #0000;
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(
          from 90deg at top var(--b) left var(--b),
          var(--_g)
        )
        0 0 / var(--_p),
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100%
        0 / var(--_p),
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
        100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
        100% 100% / var(--_p);

    width: 100%;
    max-width: 200px;
    height: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .card-text {
    margin: 0px;
    color: white;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  th,
  td {
    padding: 2%;
  }

  .card {
    display: none;
  }

  .button-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
  }
}
</style>
